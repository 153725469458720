import * as React from 'react'
import { heading } from '../components/layout.module.css'

const About = () => {
  return (
    <div>
      <title>About &ndash; Evan Gedrich</title>
      {/*<p style={{color:"#777777"}}><i>A message from the front:</i></p>*/}
      <h1 className={heading}>A note from Evan</h1>
      <p>Hi reader, thanks for visiting my site.</p>
      <p>I write to you from what was Ihepetonga (a high point of sandy soil) to the Lenape, and <a href="https://redhookwaterfront.com/2015/08/how-did-red-hook-and-the-buttermilk-channel-get-their-names/" target="_blank" rel="noreferrer">Red Hook</a>, Brooklyn to the Anglo successors of Dutch colonists&mdash;a place that presently feels like the closest thing to a quaint seaside village to be found along the immediate outer-borough waterfront of New York City. I'm satisfied here; life rolls with a steady simplicity, even in the dead of winter. Most days I walk down to the water at sunset, sit silently on a bench, and listen to the repeating harbor bell, heavy foghorn, and crying seagulls.</p>
      <p>While here, I've had an easy time keeping productive with the things I like to do; I read a lot, and write music, code, and poetry&mdash;much of which finds its way to this website here. That's, by the way, the function and purpose of this site: an online space in which my interests can coexist, a virtual bookcase for shelving my favorite texts, projects, and knick knacks. If you haven't yet, do poke around. There's a lot to hear and see (plus, I've hidden a suprise or two in the bushes for the especially bored). If, for example, you're currently reading in reluctant silence, you might as well navigate over to the music page for <i>Turning Inward</i> and play "forgetful." while you continue reading this. For example.</p>
      <p>For something like a bit about myself, since you asked: I was born to an Ecuadorian mother and Polish father somewhere between a strip mall <a href="https://www.rainforestcafe.com/location/rainforest-cafe-edison-nj/" target="_blank" rel="noreferrer">Rainforest Caf&eacute;</a> and a replica 11th-century castle housing a <a href="https://www.medievaltimes.com/lyndhurst" target="_blank" rel="noreferrer">Medieval Times</a> in the suburban coastal eastern woodlands of North America, on a day that would be annually marked by its faithfully unseasonal weather, whether that be an unusually late-season blizzard or an agreeably gentle sun shower. Doubtless I grew, learned arithmetic and dance, listened plenty, and went to <a href="https://www.divestprinceton.com/" target="_blank" rel="noreferrer">college</a>. Presently, I work an office job, on independent research projects, and on songwriting, continuing to grow, changing my mind. And now I’m writing you this note here!</p>
      <p>Finally, some comments on the current iteration of this site: if you've visited this domain any time over the past couple of years, you might remember a notably different design. This new <i>version 2.0</i> arrangment is meant to streamline the ideas from that pure HTML/CSS/JS/PHP/mySQL site into a more conventional layout using <a href="https://reactjs.org/" target="_blank" rel="noreferrer">React</a> and <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">Gatsby</a> (best of all, among the addition of plenty of other cool features, the music player now plays continuously across all pages!!). To view the code for this and the previous version of this site, along with that of my other projects, visit my <a href="https://github.com/evangedrich/" target="_blank" rel="noreferrer">GitHub</a>.</p>
      <p>Anyways, that's plenty for now. Hope you're well, would love to hear from you.</p>
      <p>Yours, Evan Gedrich <span style={{color:"#777777",opacity:"0"}}>on 2/2/22, 2:29 AM</span></p>
    </div>
  )
}

export default About
